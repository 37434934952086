// https://coolors.co/d6fff6-4dccbd-110d31-2374ab-ff8484
$dark-blue: #0d3c6a;
$white: #ffffff;

$size: 80px;
$animation-duration: 2s;

* {
	font-family: 'Albert Sans', sans-serif;
	font-size: inherit;
}

.scene {
	position: relative;
	z-index: 2;
	height: calc($size * 2.75);
	width: calc($size * 2.75);
	display: grid;
	place-items: center;
}

.cube-wrapper {
	transform-style: preserve-3d;
	animation: bouncing $animation-duration infinite;
}

.cube {
	transform-style: preserve-3d;
	transform: rotateX(45deg) rotateZ(45deg);
	animation: rotation $animation-duration infinite;
}

.cube-faces {
	transform-style: preserve-3d;
	height: $size;
	width: $size;
	position: relative;
	transform-origin: 0 0;
	transform: translateX(0) translateY(0) translateZ(calc(-#{$size} / 2));
}

.cube-face {
	position: absolute;
	inset: 0;
	background: $dark-blue;
	border: solid 1px $white;
	&.shadow {
		transform: translateZ(-$size);
		animation: bouncing-shadow $animation-duration infinite;
	}
	&.top {
		transform: translateZ($size);
	}
	&.front {
		transform-origin: 0 50%;
		transform: rotateY(-90deg);
	}
	&.back {
		transform-origin: 0 50%;
		transform: rotateY(-90deg) translateZ(-$size);
	}
	&.right {
		transform-origin: 50% 0;
		transform: rotateX(-90deg) translateY(-$size);
	}
	&.left {
		transform-origin: 50% 0;
		transform: rotateX(-90deg) translateY(-$size) translateZ($size);
	}
}

@keyframes rotation {
	$start: 45deg;

	0% {
		transform: rotateX($start) rotateY(0) rotateZ($start);
		animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
	}
	50% {
		transform: rotateX($start) rotateY(0) rotateZ($start + calc(360 / 2));
		animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
	}
	100% {
		transform: rotateX($start) rotateY(0) rotateZ($start + 360);
		animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
	}
}

@keyframes bouncing {
	0% {
		transform: translateY(-$size * 0.5);
		animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
	}

	45% {
		transform: translateY($size * 0.5);
		animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	}

	100% {
		transform: translateY(-$size * 0.5);
		animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
	}
}

@keyframes bouncing-shadow {
	0% {
		transform: translateZ(-$size) scale(1.3);
		animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
		opacity: 0.05;
	}

	45% {
		transform: translateZ(0);
		animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
		opacity: 0.3;
	}

	100% {
		transform: translateZ(-$size) scale(1.3);
		animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
		opacity: 0.05;
	}
}
