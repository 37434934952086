.login-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	row-gap: 15px;
	text-align: center;

	h1 {
		font-size: 30px;
	}
}
