// ********************************* General ********************************* //

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif !important;
}

p,
span,
a {
	font-weight: 400 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600 !important;
}

// ********************************* END General ********************************* //

main {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: rgba(13, 60, 106, 0.05) !important;
	background-repeat: no-repeat;
	background-size: cover;

	// ********************************* Header ********************************* //

	header {
		text-align: center;

		img {
			max-width: 300px;
		}
	}

	// ********************************* END Header ********************************* //

	// ********************************* Main content ********************************* //

	article {
		padding: 30px;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		row-gap: 100px;
	}

	// ********************************* END Main content ********************************* //

	// ********************************* Footer ********************************* //

	footer {
		padding: 20px;
		text-align: center;
	}

	// ********************************* END Footer ********************************* //
}
